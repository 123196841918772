import React from "react"
import { Link } from "gatsby"
import { m2p, tick } from "../assets"
import "./main.css"
import { DownloadFrom } from "../components"

const Main = props => {
  return (
    <div className="flex flex-row-l flex-column pl6-l pl5-m">
      <div className="flex-column w-60-l w-100-m   pt7-l pt6-m z-2 pages__padding">
        <h1 className="flex-wrap w-80 b mb5-ns mb4 ml3 main__header ">
          Проверка и оплата штрафов
        </h1>
        <ul className="list">
          <li className="flex">
            <img alt="tick1" title="tick1" src={tick} className="mr3" />
            <Link to="/photo" className="link f4-ns f5 b list_item_cursor">
              Фото и место нарушения
            </Link>
          </li>
          <li className="flex ">
            <img alt="tick2" title="tick2" src={tick} className="mr3" />
            <a
              className="f4-ns f5 b list_item_cursor"
              id="pay"
              onClick={props.onClick}
            >
              Оплата со скидкой 50%
            </a>
          </li>
          <li className="flex">
            <img alt="tick3" title="tick3" src={tick} className="mr3" />
            <span
              className="f4-ns f5 b list_item_cursor"
              id="nf"
              onClick={props.onClick}
            >
              Уведомление о штрафах
            </span>
          </li>
          <li className="flex">
            <img alt="tick3" title="tick3" src={tick} className="mr3" />
            <span
              className="f4-ns f5 b list_item_cursor"
              id="nf"
              onClick={props.onClick}
            >
              Гарантия удаления
            </span>
          </li>
        </ul>
        <div className="mt5-ns mt4">
          <DownloadFrom />
        </div>
      </div>
      <img
        alt="бесплатная проверка штрафов онлайн в Казахстане"
        title="приложение для проверки штрафов Казахстан"
        src={m2p}
        className=" mb0 relative-l right-0 bottom-0 pt7-l h-100-l w-50-l w-100 "
      />
    </div>
  )
}

export default Main

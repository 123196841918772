import React, { Component } from "react"
import ReactDOM from "react-dom"
import scrollToComponent from "react-scroll-to-component"

import { Layout, SMI, SEO } from "../components"
import Check from "./check"
import Main from "./main"
import Pay from "./pay"
import NewFine from "./newFine"
import {
  amanat,
  checkByAuto,
  amanatBanner,
  freedomBanner,
  freedom,
  shickardBanner,
  ecarPhoto,
  iosBannerTyres,
  iosCarSubscription,
  checkAutoPenaltiesBannerIos,
  iosBannerTyresFebruary,
  iosMasterpassBanner,
  freedomTwoMinute,
  androidBannerChecks,
  androidBannerInsurance,
  androidBannerTyres,
  androidBannerMasterpass1,
  androidBannerMasterpass2,
  androidBannerCTOGramm,
  androidBannerCTOGrammBig,
  androidBannerCarParts,
  androidBannerMakingMats,
  androidBannerMakingMatsBig,
  androidCheckTechInspection,
  iosCheckTechInspection,
  androidCheckPenaltiesAndGetProtocol,
  iosCheckPenaltiesAndGetProtocol,
  iosBannerMasterpass,
  androidBannerUpdatePreview,
  androidBannerCTO,
  androidBannerAutoMarket
} from "../assets"

import "./main.css"

export default class IndexPage extends Component {
  state = {
    activeSection: "main",
    activeNav: "nav__box__element nav__box__element__active",
    notActiveNav: "nav__box__element",
    activeSMI: "nav__box__element nav__box__element__active active__smi",
  }

  componentDidMount = () => {
    if (window.innerWidth > 1023) {
      window.addEventListener("scroll", this.handleScroll)
    }
  }

  componentWillUnmount = () => {
    if (window.innerWidth > 1023) {
      window.removeEventListener("scroll", this.handleScroll)
    }
  }

  handleScroll = () => {
    const main = ReactDOM.findDOMNode(this.refs.main)
    const check = ReactDOM.findDOMNode(this.refs.check)
    const pay = ReactDOM.findDOMNode(this.refs.pay)
    const nf = ReactDOM.findDOMNode(this.refs.nf)
    const smi = ReactDOM.findDOMNode(this.refs.smi)

    const offsetMain = this.getOffset(main)
    const offsetCheck = this.getOffset(check)
    const offsetPay = this.getOffset(pay)
    const offsetNF = this.getOffset(nf)
    const offsetSMI = this.getOffset(smi)

    if (offsetMain.bottom > window.innerHeight / 3) {
      this.setState({ activeSection: "main" })
    } else if (offsetCheck.bottom > window.innerHeight / 3) {
      this.setState({ activeSection: "check" })
    } else if (offsetPay.bottom > window.innerHeight / 3) {
      this.setState({ activeSection: "pay" })
    } else if (offsetNF.bottom > window.innerHeight / 3) {
      this.setState({ activeSection: "nf" })
    } else if (offsetSMI.bottom > window.innerHeight / 3) {
      this.setState({ activeSection: "smi" })
    }
  }

  getOffset = e => {
    const bounding = e.getBoundingClientRect()
    return {
      bottom: bounding.bottom,
    }
  }

  changeCurrentSection = e => {
    this.setState({ activeSection: e.currentTarget.id })
    if (e.currentTarget.id === "main") {
      scrollToComponent(this.refs.main, {
        offset: 0,
        align: "top",
        duration: 1500,
      })
    }
    if (e.currentTarget.id === "check") {
      scrollToComponent(this.refs.check, {
        offset: 0,
        align: "top",
        duration: 1500,
      })
    }
    if (e.currentTarget.id === "pay") {
      scrollToComponent(this.refs.pay, {
        offset: 0,
        align: "top",
        duration: 1500,
      })
    }
    if (e.currentTarget.id === "nf") {
      scrollToComponent(this.refs.nf, {
        offset: 0,
        align: "top",
        duration: 1500,
      })
    }
    if (e.currentTarget.id === "smi") {
      scrollToComponent(this.refs.smi, {
        offset: 0,
        align: "top",
        duration: 1500,
      })
    }
  }

  render() {
    return (
      <Layout>
        <SEO
          title="Проверка штрафов в Казахстане онлайн по гос номеру авто, техпаспорту, ИНН | OKauto"
          description="Наш сервис предлагает приложение для проверки и оплаты штрафов ПДД по номеру авто, ИНН или по техпаспорту. С нами вы легко сможете узнать штрафы ПДД и мгновенно оплатить их не затрачивая массу времени на ввод лишних данных"
        />
        <nav className="db-l dn nav__box h-100 z-max">
          <div className="flex flex-column">
            <a className="pv3" id="main" onClick={this.changeCurrentSection}>
              <div
                className={
                  this.state.activeSection === "main"
                    ? this.state.activeNav
                    : this.state.notActiveNav
                }
              />
            </a>

            <a className="pv3" id="check" onClick={this.changeCurrentSection}>
              <div
                className={
                  this.state.activeSection === "check"
                    ? this.state.activeNav
                    : this.state.notActiveNav
                }
              />
            </a>
            <a className="pv3" id="pay" onClick={this.changeCurrentSection}>
              <div
                className={
                  this.state.activeSection === "pay"
                    ? this.state.activeNav
                    : this.state.notActiveNav
                }
              />
            </a>

            <a className="pv3" id="nf" onClick={this.changeCurrentSection}>
              <div
                className={
                  this.state.activeSection === "nf"
                    ? this.state.activeNav
                    : this.state.notActiveNav
                }
              />
            </a>
            <a className="pv3" id="smi" onClick={this.changeCurrentSection}>
              <div
                className={
                  this.state.activeSection === "smi"
                    ? this.state.activeSMI
                    : this.state.notActiveNav
                }
              />
            </a>
          </div>
        </nav>
        <section id="main" ref="main">
          <Main onClick={this.changeCurrentSection} />
        </section>
        <section id="checkSection" ref="check">
          <Check />
        </section>
        <section id="paySection" ref="pay">
          <Pay />
        </section>
        <section id="newSection" ref="nf">
          <NewFine />
        </section>
        <section id="smiSection" ref="smi">
          <SMI />
        </section>
        <img alt="amanat" title="amanat" src={amanat} className="dn" />
        <img alt="freedom" title="freedom" src={freedom} className="dn" />
        <img
          alt="amanatBanner"
          title="amanatBanner"
          src={amanatBanner}
          className="dn"
        />
        <img
          alt="checkbyauto"
          title="checkbyauto"
          src={checkByAuto}
          className="dn"
        />
        <img
          alt="freedomBanner"
          title="freedomBanner"
          src={freedomBanner}
          className="dn"
        />
        <img
          alt="androidBannerChecks"
          title="androidBannerChecks"
          src={androidBannerChecks}
          className="dn"
        />
        <img
          alt="androidBannerInsurance"
          title="androidBannerInsurance"
          src={androidBannerInsurance}
          className="dn"
        />
        <img
          alt="androidBannerTyres"
          title="androidBannerTyres"
          src={androidBannerTyres}
          className="dn"
        />
        <img
          alt="androidBannerMasterpass1"
          title="androidBannerMasterpass1"
          src={androidBannerMasterpass1}
          className="dn"
        />
        <img
          alt="androidBannerMasterpass2"
          title="androidBannerMasterpass2"
          src={androidBannerMasterpass2}
          className="dn"
        />
        <img
          alt="androidBannerCarParts"
          title="androidBannerCarParts"
          src={androidBannerCarParts}
          className="dn"
        />
        <img
          alt="androidBannerCTOGramm"
          title="androidBannerCTOGramm"
          src={androidBannerCTOGramm}
          className="dn"
        />
        <img
          alt="androidBannerCTOGrammBig"
          title="androidBannerCTOGrammBig"
          src={androidBannerCTOGrammBig}
          className="dn"
        />
        <img
          alt="freedomTwoMinute"
          title="freedomTwoMinute"
          src={freedomTwoMinute}
          className="dn"
        />
        <img
          alt="shickardBanner"
          title="shickardBanner"
          src={shickardBanner}
          className="dn"
        />
        <img alt="ecarPhoto" title="ecarPhoto" src={ecarPhoto} className="dn" />
        <img
          alt="iosBannerTyres"
          title="iosBannerTyres"
          src={iosBannerTyres}
          className="dn"
        />
        <img
          alt="iosCarSubscription"
          title="iosCarSubscription"
          src={iosCarSubscription}
          className="dn"
        />
        <img
          alt="iosBannerMasterpass"
          title="iosBannerMasterpass"
          src={iosBannerMasterpass}
          className="dn"
        />
        <img
          alt="checkAutoPenaltiesBannerIos"
          title="checkAutoPenaltiesBannerIos"
          src={checkAutoPenaltiesBannerIos}
          className="dn"
        />
        <img
          alt="iosBannerTyresFebruary"
          title="iosBannerTyresFebruary"
          src={iosBannerTyresFebruary}
          className="dn"
        />
        <img
          alt="iosMasterpassBanner"
          title="iosMasterpassBanner"
          src={iosMasterpassBanner}
          className="dn"
        />
        <img
          alt="androidBannerUpdatePreview"
          title="androidBannerUpdatePreview"
          src={androidBannerUpdatePreview}
          className="dn"
        />
        <img
          alt="androidBannerMakingMats"
          title="androidBannerMakingMats"
          src={androidBannerMakingMats}
          className="dn"
        />
        <img
          alt="androidBannerMakingMatsBig"
          title="androidBannerMakingMatsBig"
          src={androidBannerMakingMatsBig}
          className="dn"
        />
        <img
          alt="androidCheckTechInspection"
          title="androidCheckTechInspection"
          src={androidCheckTechInspection}
          className="dn"
        />
        <img
          alt="iosCheckTechInspection"
          title="iosCheckTechInspection"
          src={iosCheckTechInspection}
          className="dn"
        />
        <img
          alt="androidCheckPenaltiesAndGetProtocol"
          title="androidCheckPenaltiesAndGetProtocol"
          src={androidCheckPenaltiesAndGetProtocol}
          className="dn"
        />
        <img
          alt="iosCheckPenaltiesAndGetProtocol"
          title="iosCheckPenaltiesAndGetProtocol"
          src={iosCheckPenaltiesAndGetProtocol}
          className="dn"
        />
        <img
          alt="androidBannerCTO"
          title="androidBannerCTO"
          src={androidBannerCTO}
          className="dn"
        />
          <img
              alt="androidBannerAutoMarket"
              title="androidBannerAutoMarket"
              src={androidBannerAutoMarket}
              className="dn"
          />
      </Layout>
    )
  }
}
